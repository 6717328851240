import React from "react";
import {
    Container,
    Navbar,
    NavDropdown,
    Nav,
    Col,
    Row
} from "react-bootstrap";
import Logo from "../../assets/logo.png";
import "./nav.css";
import Phone from "../../assets/icons/phone.png";
import Whatsapp from "../../assets/icons/whatsapp.png";
import {BrowserView, MobileView} from "react-device-detect";
import Carousel from 'react-bootstrap/Carousel';
import Img1 from '../../assets/album/01.jpg'
import Img2 from '../../assets/album/02.jpg'
import Img3 from '../../assets/album/03.jpg'
import Img4 from '../../assets/album/04.jpg'
import Img5 from '../../assets/album/05.jpg'
import Img6 from '../../assets/album/06.jpg';
import ReactWhatsapp from 'react-whatsapp';

function NavBar() {
    return (
        <div id="home">
            <Carousel>
                <Carousel.Item>
                    <img className="d-block"
                        src={Img1}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block"
                        src={Img2}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block"
                        src={Img3}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block"
                        src={Img4}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block"
                        src={Img5}/>
                </Carousel.Item>
            </Carousel>
            <div className="layer2">

                <center>
                    <img src={Logo}
                        className="logo mt-3"/>
                </center>


                <div className="mains1">
                    <Container className="main2">

                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={8}
                                xs={12}>
                                <center>
                                    <BrowserView>
                                        <h1 className="quote">
                                            Buon Appetito<br/>
                                            With Zestful Food
                                        </h1>
                                    </BrowserView>
                                    <MobileView>
                                        <h1 className="quote2">
                                            Buon Appetito<br/>
                                            With Zestful Food
                                        </h1>
                                    </MobileView>

                                </center>
                            </Col>
                            <Col lg={1}
                                xs={9}></Col>
                            <Col lg={1}
                                xs={3}>
                                <BrowserView>
                                    <div className="c-icon">
                                        <a href="tel:+971524420037" className="m-1"><img width={50}
                                                height={50}
                                                src={Phone}/></a><br/>
                                        <ReactWhatsapp number="+971524420037" style={{background:"none",border:"none"}}><img width={60}
                                                height={60}
                                                src={Whatsapp}/></ReactWhatsapp>
                                    </div>
                                </BrowserView>
                                <MobileView>
                                    <div className="c-icon2" style={{position:'relative'}}>
                                    <a href="tel:+971524420037"  style={{zIndex:3,width:50,height:50}}><img width={50}
                                                height={50}
                                                src={Phone} style={{position:"relative",left:-7}}/></a><br/>
                                        <ReactWhatsapp number="+971524420037" style={{background:"none",border:"none"}}><img width={60}
                                                height={60}
                                                src={Whatsapp} style={{position:"relative",left:-20,}}/></ReactWhatsapp>
                                    </div>
                                </MobileView>
                            </Col>
                        </Row>


                    </Container>
                </div>
            </div>
        </div>
    );
}

export default NavBar;
