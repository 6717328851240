import React, {useState} from "react";
import {Col, Collapse, Row} from "react-bootstrap";

import Img from "../../assets/main1.png";
import Img2 from "../../assets/album/02.jpg";
import TKS from '../../assets/TKS.png';
import "./sec2.css";

const Section4 = () => {

    const [selected, setSelected] = useState(1);

    return (
        <div className="container mt-5">
            <Row>
                <Col lg={6}>
                    <Row>
                        <Col style={
                            {paddingRight: 0}
                        }>
                            {
                            selected == 1 ? <button className="btn1 w-100"
                                onClick={
                                    () => setSelected(1)
                            }>Ajman</button> : <button className="btn2 w-100"
                                onClick={
                                    () => setSelected(1)
                            }>Ajman</button>
                        } </Col>
                        <Col style={
                            {paddingLeft: 0}
                        }>
                            {
                            selected == 1 ? (
                                <button className="btn2 w-100"
                                    onClick={
                                        () => setSelected(2)
                                }>Dubai</button>
                            ) : (
                                <button className="btn1 w-100"
                                    onClick={
                                        () => setSelected(2)
                                }>Dubai</button>
                            )
                        } </Col>
                    </Row>

                    {
                    selected == 1 ? (
                        <img src={Img}
                            className="w-100"/>
                    ) : (
                        <img src={Img2}
                            className="w-100"/>
                    )
                }


                    {
                    selected == 1 ? (
                        <p className="mt-2">AL JERF -2 NEAR DELTA CENTRE,<br/>OPP.,NESTO HYPER MARKET AJMAN - UAE<br/>TEL :+971524420037</p>
                    ) : (
                        <p>JVC, Dubai<br/>
                        Ph: 044435777</p>
                    )
                } </Col>
                <Col lg={6}
                    xs={12}>
                    {/* <div>
                    <Row className="mt-2">
                        <Col lg={1}
                            xs={1}></Col>
                        <Col>
                            <p className="grey-color">Monday</p>
                        </Col>
                        <Col>
                            <p className="grey-color">Monday</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={1}
                            xs={1}></Col>
                        <Col>
                            <p className="grey-color">Tuesday</p>
                        </Col>
                        <Col>
                            <p className="grey-color">12:00 pm - 10:00 pm</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={1}
                            xs={1}></Col>
                        <Col>
                            <p className="grey-color">Wednesday</p>
                        </Col>
                        <Col>
                            <p className="grey-color">12:00 pm - 10:00 pm</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={1}
                            xs={1}></Col>
                        <Col>
                            <p className="grey-color">Thursday</p>
                        </Col>
                        <Col>
                            <p className="grey-color">12:00 pm - 10:00 pm</p>
                        </Col>
                    </Row>
                    <Row className="selected">
                        <Col lg={1}
                            xs={1}><div className="dot"/></Col>
                        <Col>
                            <p className="black-color">Friday</p>
                        </Col>
                        <Col>
                            <p className="black-color">05:00 pm - 10:30 pm</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={1}
                            xs={1}></Col>
                        <Col>
                            <p className="grey-color">Saturday</p>
                        </Col>
                        <Col>
                            <p className="grey-color">12:00 pm - 10:00 pm</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={1}
                            xs={1}></Col>
                        <Col>
                            <p className="grey-color">Sunday</p>
                        </Col>
                        <Col>
                            <p className="grey-color">12:00 pm - 10:00 pm</p>
                        </Col>
                    </Row>
                    <center>
                        <i style={
                            {fontSize: 12}
                        }>3:57 pm Friday, 20 January 2023 (GST)</i>
                    </center>
                    </div> */}
                    <center>
                        <img src={TKS}
                            width={100}
                            className="mt-5"/>
                        <h6 className="green-color mt-3">Managed and operated by :</h6>
                        <h5 className="text-center">The Kitchen Station Restaurant L L C - UAE</h5>
                        <p>Tel:+971524420037</p>
                        <p>Email : info@restolita.com</p>
                        {/* <p>For Takeaway,Call or Whatsapp : 055 2674999</p> */}
                    </center>
                </Col>
            </Row>
        </div>
    );
};

export default Section4;
