import React from "react";
import {Col, Row} from "react-bootstrap";
import Img1 from '../../assets/Foods/1.png';
import Img2 from '../../assets/Foods/2.png';
import Img3 from '../../assets/Foods/3.png';
import Img4 from '../../assets/Foods/4.png';
import Img5 from '../../assets/Foods/5.png';
import Img6 from '../../assets/Foods/6.png';
import Img7 from '../../assets/Foods/7.png';
import Img8 from '../../assets/Foods/8.png';
import Img9 from '../../assets/Foods/9.png';
import Img10 from '../../assets/Foods/10.png';

import {BrowserView, MobileView} from "react-device-detect";

import "./sec1.css";
import Stories from "../Stories";

const Section1 = () => {
    return (
        <div className="container  mt-5 sec1main" id="about">
            <Row>
                <Col></Col>
                <Col lg={8}
                    xs={12}>
                    <div className="seca p-0">
                        <p>
                            A plate of risotto and ravioli complemented with the most popular pizza is the best Italian combo to dig in and hey! How can we forget a glass of vino with this? Italian delicacies always bring delight to the taste buds and soul.
                            <br/><br/>
                            Home to authentic and soulful Italian cuisines- Restolita serves the best Italian foods here in UAE. When it is about quality, we never settle on mediocrity. We believe in serving quality food and thus our cuisines are cooked with rich high quality and finest ingredients.
                        </p>
                    </div>
                </Col>
                <Col></Col>
            </Row>

            <Stories/>

            <BrowserView>
                <Row>
                    <Col md={1}
                        lg={1}></Col>
                    <Col md={2}
                        lg={2}><img src={Img1}
                            className="w-100"/></Col>
                    <Col md={2}
                        lg={2}><img src={Img2}
                            className="w-100"/></Col>
                    <Col md={2}
                        lg={2}><img src={Img3}
                            className="w-100"/></Col>
                    <Col md={2}
                        lg={2}><img src={Img4}
                            className="w-100"/></Col>
                    <Col md={2}
                        lg={2}><img src={Img5}
                            className="w-100"/></Col>
                    <Col md={1}
                        lg={1}></Col>
                </Row>
                <Row className="mt-4">
                    <Col md={1}
                        lg={1}></Col>
                    <Col md={2}
                        lg={2}><img src={Img6}
                            className="w-100"/></Col>
                    <Col md={2}
                        lg={2}><img src={Img7}
                            className="w-100"/></Col>
                    <Col md={2}
                        lg={2}><img src={Img8}
                            className="w-100"/></Col>
                    <Col md={2}
                        lg={2}><img src={Img9}
                            className="w-100"/></Col>
                    <Col md={2}
                        lg={2}><img src={Img10}
                            className="w-100"/></Col>
                    <Col md={1}
                        lg={1}></Col>
                </Row>
            </BrowserView>
            <MobileView>
                <Row>

                    <Col xs={4}><img src={Img1}
                            className="w-100"/></Col>
                    <Col xs={4}><img src={Img2}
                            className="w-100"/></Col>
                    <Col xs={4}><img src={Img3}
                            className="w-100"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={4}><img src={Img4}
                            className="w-100"/></Col>
                    <Col xs={4}><img src={Img5}
                            className="w-100"/></Col>
                    <Col xs={4}><img src={Img6}
                            className="w-100"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={4}><img src={Img7}
                            className="w-100"/></Col>
                    <Col xs={4}><img src={Img8}
                            className="w-100"/></Col>
                    <Col xs={4}><img src={Img9}
                            className="w-100"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col></Col>
                    <Col xs={4}><img src={Img10}
                            className="w-100"/></Col>
                    <Col></Col>
                </Row>
            </MobileView>
            <Row className="mt-5">
                <Col></Col>
                <Col lg={8}
                    xs={12}>
                    <div className="seca">
                        <p>Established in 2018 as The Kitchen Station LLC in Emirates of Ajman, the brand was relabelled as RESTOLITA. After the success in Ajman, we made headway to Dubai where we made the synonymous success as in Ajman.</p>
                    </div>
                </Col>
                <Col></Col>
            </Row>
        </div>
    );
};

export default Section1;
