import React from 'react'
import {Row, Col, Container} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import {MobileView, BrowserView} from 'react-device-detect';
import Img1 from '../../assets/album/s1.webp'
import Img2 from '../../assets/album/s2.webp'
import Img3 from '../../assets/album/s3.webp'
import Img4 from '../../assets/album/s4.webp'
import Img5 from '../../assets/album/s5.webp'
import './stories.css';
import ImageGallery from "react-image-gallery";

const Stories = () => {

    const images = [
        {
            original: Img1,
            thumbnail: Img1
        }, {
            original: Img2,
            thumbnail: Img2
        }, {
            original: Img4,
            thumbnail: Img4
        }, {
            original: Img5,
            thumbnail: Img5
        },

    ];

    return (
        <div className='stories-bg w-100'>
            <div className='stories w-100'>
                <h4 className='art text-center'>A Blend of Food , Art & Culture</h4>
                {/* <BrowserView>
                    <Carousel>
                        <Carousel.Item>
                            <center>
                                <img className="story"
                                    src={Img1}/>
                            </center>
                        </Carousel.Item>
                        <Carousel.Item>
                            <center>
                                <img className="story"
                                    src={Img2}/></center>
                        </Carousel.Item>
                        <Carousel.Item>
                            <center>
                                <img className="story"
                                    src={Img3}/></center>
                        </Carousel.Item>
                        <Carousel.Item>
                            <center>
                                <img className="story"
                                    src={Img4}/></center>
                        </Carousel.Item>
                        <Carousel.Item>
                            <center><img className="story"
                                    src={Img5}/></center>
                        </Carousel.Item>
                    </Carousel>
                </BrowserView>
                <MobileView>
                    <Carousel>
                        <Carousel.Item>
                            <center>
                                <img className="story2"
                                    src={Img1}/>
                            </center>
                        </Carousel.Item>
                        <Carousel.Item>
                            <center>
                                <img className="story2"
                                    src={Img2}/></center>
                        </Carousel.Item>
                        <Carousel.Item>
                            <center>
                                <img className="story2"
                                    src={Img3}/></center>
                        </Carousel.Item>
                        <Carousel.Item>
                            <center>
                                <img className="story2"
                                    src={Img4}/></center>
                        </Carousel.Item>
                        <Carousel.Item>
                            <center><img className="story2"
                                    src={Img5}/></center>
                        </Carousel.Item>
                    </Carousel>
                </MobileView> */}
                <Container className='mt-2 shadow'>
                    <ImageGallery items={images}
                        />
                </Container>

                <div className='thick-line'/>

            </div>
        </div>
    )
}

export default Stories
