import React from "react";
import About from "../../components/About";
import NavBar from "../../components/NavBar";
import Section1 from "../../components/Section1";
import Section2 from "../../components/Section2";
import "./home.css";
import Services from "../../components/Section3";
import Footer from "../../components/Footer";
import ContactUs from "../../components/ContactUs/";
import Section3 from "../../components/Section3";
import Section4 from "../../components/Section4";

const Home = () => {
  return (
    <div className="home">
      <NavBar />

      <Section1 />
      <Section2/>
      <Section3 />
      <Section4 />

      <Footer />
    </div>
  );
};

export default Home;
