import React from "react";
import "./footer.css";
import Instagram from "../../assets/icons/insta.png";
import LinkedIn from "../../assets/icons/ln.png";
import Facebook from "../../assets/icons/fb.png";
import Arrow from "../../assets/icons/arrow.png";
import {Col, Row} from "react-bootstrap";
import {BrowserView, MobileView} from "react-device-detect";

function Footer() {
    return (
        <div className="foot">
            <BrowserView>
                <div className="grey-card">
                    <Row>
                        <Col lg={3}></Col>
                        <Col lg={2}
                            xs={4}>

                            <center>
                                <a href="https://www.instagram.com/restolita/">
                                    <img src={Instagram}/></a>
                            </center>
                        </Col>

                        <Col lg={2}
                            xs={4}>
                            <center>
                                <a href="https://www.facebook.com/restolita?mibextid=LQQJ4d">
                                    <img src={Facebook}/></a>
                            </center>
                        </Col>
                        <Col lg={2}
                            xs={4}>
                            <center><img src={LinkedIn}/></center>
                        </Col>
                        <Col lg={3}></Col>

                    </Row>
                    <center>
                        <p className="m-4">Stay up to date with the latest news<br/>
                            and relevant updates from Restolita</p>
                        <Row className="searchbar">
                            <Col lg={8}>
                                <input type={"text"}
                                    placeholder="Enter email address"/>
                            </Col>
                            <Col>
                                <img src={Arrow}/>
                            </Col>
                        </Row>

                    </center>
                </div>
            </BrowserView>
            <MobileView>
                <div className="grey-card2 p-5">
                    <Row>
                        <Col lg={3}></Col>
                        <Col lg={2}
                            xs={4}>
                            <center>
                                <a href="https://www.instagram.com/restolita/">
                                    <img src={Instagram}/></a>
                            </center>
                        </Col>
                       
                        <Col lg={2}
                            xs={4}>
                            <center>
                                <a href="https://www.facebook.com/restolita?mibextid=LQQJ4d">
                                    <img src={Facebook}/></a>
                            </center>
                        </Col>
                        <Col lg={2}
                            xs={4}>
                            <center>
                                <a href="">
                                    <img src={LinkedIn}/></a>
                            </center>
                        </Col>
                        <Col lg={3}></Col>

                    </Row>
                    <center>
                        <p className="m-4">Stay up to date with the latest news and relevant updates from Restolita</p>
                        <Row className="searchbar">
                            <Col lg={8}
                                xs={8}>
                                <input type={"text"}
                                    placeholder="Enter email address"/>
                            </Col>
                            <Col>
                                <img src={Arrow}/>
                            </Col>
                        </Row>

                    </center>
                </div>
            </MobileView>
        </div>
    );
}

export default Footer;
