import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./services.css";
import Icon1 from '../../assets/icons/vision.png';
import Icon2 from '../../assets/icons/mission.png';
import Icon3 from '../../assets/icons/values.png';
import {BrowserView, MobileView} from "react-device-detect";

const Section3 = () => {
    return (
        <div className="servMain mt-5">

            <div className="servMain2">
                <BrowserView>
                    <Container>
                        <Row>
                            <Col></Col>
                            <Col lg={5}>
                                <div className="card1">
                                    <img src={Icon1}
                                        className="sicon"/>
                                    <h4 className="fr mt-2">Vision</h4>
                                    <p className="fr">To stimulate the senses with<br/>luscious and delectable cuisines.</p>
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="card2">
                                    <img src={Icon2}
                                        className="sicon2"/>
                                    <h4 className="mt-2">Mission</h4>
                                    <p className="fl">To bring palatable and finest<br/>cuisines to your platter.</p>
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Col></Col>
                            <Col lg={7}>
                                <div className="card3">
                                    <img src={Icon3}
                                        className="sicon3"/>
                                    <h4 className="text-center mt-3">Our Values</h4>
                                    <p>Our priority is YOU and thus we always strive to deliver cuisines that make you appeased and contented in an atmosphere that pleases you.</p>
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </BrowserView>
                <MobileView>
                    <Container>

                        <div className="card1">
                            <img src={Icon1}
                                className="sicon0"/>
                            <h4 className="fr mt-3">Vision</h4>
                            <p className="fr">To stimulate the senses with<br/>luscious and delectable cuisines.</p>
                        </div>

                        <div className="card2 mt-5">
                            <img src={Icon2}
                                className="sicon0"/>
                            <h4 className="mt-2">Mission</h4>
                            <p className="fl">To bring palatable and finest<br/>cuisines to your platter.</p>
                        </div>

                        <div className="card3 mt-5">
                            <img src={Icon3}
                                className="sicon0"/>
                            <h4 className="text-center mt-3">Our Values</h4>
                            <p>Our priority is YOU and thus we always strive to deliver cuisines that make you appeased and contented in an atmosphere that pleases you.</p>
                        </div>

                    </Container>
                </MobileView>
            </div>
        </div>
    );
};

export default Section3;
